import React, { useEffect, useRef, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import { notifyError, notifyTopRight } from "../components/Common/Toaster";
import { postSmsNotification } from "../../services/PushNotification/NotificationService";
import ButtonLoader from "../components/Common/ButtonLoader/ButtonLoader";
import addPhoto from "../../services/Auth/AuthService";
import Select from "react-select/async";
import { getUserList } from "../../services/UserServices/UserService";

export default function SmsNotification({ updateNotification, setUpdateNotification }) {
  let errorsObj = {
    sendedTo: "",
  };
  const formRef = useRef(null);
  const [errors, setErrors] = useState(errorsObj);
  const [formData, setFormData] = useState({
    message: "",
    sendedTo: "",
  });

  const [loader, setLoader] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(null);

  const handleChangeText = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const handleInput = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
    setErrors({ ...errors, [name]: "" });
  };

  console.log(selectedUsers, "formData");
  //// function to post data ///
  const onSubmit = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (formData?.message.trim() === "") {
      errorObj.message = "Message is Required";
      error = true;
    }
    if (formData?.sendedTo.trim() === "") {
      errorObj.sendedTo = "Send To is Required";
      error = true;
    }
    setErrors(errorObj);

    if (error) {
      return;
    }

    setLoader(true);

    try {
      const response = await postSmsNotification(formData);
      notifyTopRight("Sent Successfully.");
      setUpdateNotification(true);

      setFormData({
        message: "",
        sendedTo: "",
      });

      setLoader(false);
    } catch (error) {
      setLoader(false);
      notifyError("Something went wrong please try again!");
    }
  };

  return (
    <div>

      <div className="">
        <div className="authincation-content text-black p-5">
          <div className="mb-4">
            <h3 className="mb-1 font-w600 text-black ">Add Details</h3>
          </div>

          <form onSubmit={onSubmit} ref={formRef}>

            <div className="form-group">
              <label className="mb-2">
                <strong className="">Message</strong>
              </label>
              <textarea
                className="form-control"
                name="message"
                value={formData.message}
                onChange={handleChangeText}
                placeholder="Enter message"
                rows="6"
              />
              {errors.message && (
                <div className="text-danger fs-12">{errors.message}</div>
              )}
            </div>

            <div className="form-group">
              <label className="mb-2">
                <strong className="">Send To</strong>
              </label>
              <select
                className="form-control"
                name="sendedTo"
                value={formData.sendedTo}
                onChange={handleInput}
              >
                <option value="">Select Option</option>
                <option value="all">All</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="premium">Member</option>
                <option value="nonPremium">Non Member</option>
                <option value="deactivatedMale">Deactivated Male</option>
                <option value="deactivatedFemales">Deactivated Females</option>
                <option value="deletedMale">Deleted Male</option>
                <option value="deletedFemale">Deleted Female</option>
                <option value="pastMembers">Past Members</option>
              </select>
              {errors.sendedTo && (
                <div className="text-danger fs-12">{errors.sendedTo}</div>
              )}
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="btn btn-primary btn-block"
                disabled={loader}
              >
                {loader ? (
                  <>
                    <ButtonLoader /> Send
                  </>
                ) : (
                  "Send"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
