import React, { useEffect, useRef, useState } from "react";
import { Table, Badge, Dropdown } from "react-bootstrap";
import {
  getUserList,
  blockUser,
  deleteUser,
  getUserExportList,
  sendMessageApi,
} from "../../services/UserServices/UserService";
import Spinner from "../components/Common/Spinner";
import { notifyError, notifyTopRight } from "../components/Common/Toaster";
import Pagination from "../components/Common/Pagination";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import dummmyImg from "../../images/profile/images.jpeg";
import { RangeDatePicker } from "@y0c/react-datepicker";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  activeStatusFilterPost,
  formFilledFilterPost,
  genderFilterPost,
  resetFilterPost,
  serachFilterPost,
  statusFilterPost,
  typeFilterPost,
} from "../../store/actions/FilterActions";
import * as XLSX from "xlsx";
import Select, { components } from "react-select/async";
import ButtonLoader from "../components/Common/ButtonLoader/ButtonLoader";

const SendMessages = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const imgBaseURl = process.env.REACT_APP_IMAGE_BASEURL;
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [listLength, setListLength] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [imageForView, setImageForView] = useState("");
  const [view, setView] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    location?.state?.page ? location?.state?.page : 0
  );
  const [options, setOptions] = useState([]);
  const [loginData, setLoginData] = useState(null);
  const formFilledFilter = useSelector(
    (state) => state.filter.filter.formFilled
  );
  const typeFilter = useSelector((state) => state.filter.filter.type);
  const activeStatusFilter = useSelector(
    (state) => state.filter.filter.activeStatus
  );

  const [search, setSearch] = useState("");
  const [initialFetch, setInitialFetch] = useState(true);
  const [gender, setGender] = useState("all");
  const [status, setStatus] = useState("all");
  const [isFormFilled, setIsFormFilled] = useState("all");
  const [signUpType, setSignUpType] = useState("all");
  const [activeStatus, setActiveStatus] = useState("createdAt");
  const [newUsers, setNewUsers] = useState([]);
  const limit = 10;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [exportLoader, setExportLoader] = useState(false);
  const [hideFromSender, setHideFromSender] = useState(true);
  const [allUsers, setAllUsers] = useState([]);

  function onDateChange(...args) {
    setStartDate(moment(args[0]).format("YYYY-MM-DD"));
    {
      args[1]
        ? setEndDate(moment(args[1]).format("YYYY-MM-DD"))
        : setEndDate(null);
    }
  }
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const headers = [
    { label: "CONTACT ID", key: "_id" },
    { label: "EMAIL", key: "email" },
    { label: "FIRST NAME", key: "name" },
    { label: "LAST NAME", key: "" },
    { label: "SMS", key: "phoneNumber" },
  ];
  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await getUserList(
        currentPage,
        limit,
        search,
        gender,
        status,
        isFormFilled,
        signUpType,
        activeStatus,
        startDate,
        endDate
      );
      setUsers(response.data.data.users);
      const total = response.data.data.count;
      setPageCount(Math.ceil(total / limit));
      setListLength(total);
      setLoader(false);
      setInitialFetch(false);
    } catch (error) {
      setLoader(false);
    }
  };
  function onReset() {
    // setDateRangePickerKey((prev) => prev + 1);
    setStartDate(null);
    setEndDate(null);
  }
  const onDelete = async (id) => {
    setLoader(true);
    try {
      const response = await deleteUser(id);
      notifyTopRight(response.data?.message);
      getTableData();
      setLoader(false);
    } catch (error) {
      notifyError(error.response?.data?.message);
      console.log(error.response, "error");
      setLoader(false);
    }
  };

  const onAction = async (id) => {
    setLoader(true);
    try {
      const response = await blockUser(id);
      notifyTopRight(response.data?.message);
      getTableData();
      setLoader(false);
    } catch (error) {
      notifyError(error.response?.data?.message);
      console.log(error.response, "error");
      setLoader(false);
    }
  };

  useEffect(() => {
    // getTableData();
    loadUsers();
  }, [
    currentPage,
    gender,
    status,
    isFormFilled,
    signUpType,
    activeStatus,
    endDate,
  ]);

  let timeoutId;
  // Define a helper function for debouncing
  const debounce = (func, delay) => {
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  const debouncedHandleFetch = debounce(getTableData, 500);
  useEffect(() => {
    if (initialFetch === false) {
      setCurrentPage(0);
      debouncedHandleFetch();
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, []);

  let errorsObj = {
    message: "",
  };
  const formRef = useRef(null);
  const [errors, setErrors] = useState(errorsObj);
  const [formData, setFormData] = useState({
    message: "",
  });

  const [selectedUsers, setSelectedUsers] = useState(null);
  const [selectedRecivers, setSelectRecivers] = useState([]);

  const handleInput = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
    setErrors({ ...errors, [name]: "" });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (selectedUsers === null) {
      notifyError("Sender is Required");
      return;
    }
    if (selectedRecivers.length === 0) {
      notifyError("Recivers is Required");
      return;
    }
    if (formData?.message.trim() === "") {
      errorObj.message = "Message is Required";
      error = true;
    }
    setErrors(errorObj);

    if (error) {
      return;
    }

    setLoader(true);

    const data = selectedRecivers.map((reciver) => {
      return reciver.value;
    });

    const { userId } = extractUserInfo(selectedUsers);

    try {
      const dataToSend = {
        ...formData,
        gender: gender,
        userList: status,
        formFill: isFormFilled,
        loginType: signUpType,
        sortBy: activeStatus,
        senderId: selectedUsers !== null ? userId : "",
        receiverIds: data,
        hideForSender: hideFromSender,
        sendToAll: data[0] === "select-all" ? true : false,
        isFreshConversation: data[0] === "fresh-conversation" ? true : false,
      };

      const response = await sendMessageApi(dataToSend);
      notifyTopRight("Sent Successfully.");
      setFormData({
        message: "",
      });

      setLoader(false);
    } catch (error) {
      setLoader(false);
      notifyError("Something went wrong please try again!");
    }
  };

  const loadUsers = async (inputValue = "") => {
    try {
      const response = await getUserList(
        currentPage,
        100,
        inputValue,
        gender,
        status,
        isFormFilled,
        signUpType,
        activeStatus,
        startDate,
        endDate
      );

      const users = response.data.data.users.map((user) => ({
        value: user._id,
        label: (
          <div className="flex items-center">
            <img
              src={
                user?.images[0]?.url
                  ? imgBaseURl + user?.images[0]?.url
                  : dummmyImg
              }
              alt={user.name}
              className="w-8 h-8 rounded-full mr-2"
              width={50}
              height={40}
              style={{ marginRight: "10px" }}
            />
            {user.name} ({user.email ? user.email : user.phoneNumber})
          </div>
        ),
      }));

      const selectAllOption = {
        value: "select-all",
        label: "Select All",
      };

      const selectFreshConversationOption = {
        value: "fresh-conversation",
        label: "Fresh Conversation",
      };

      setAllUsers([selectAllOption, selectFreshConversationOption, ...users]);
      return [selectAllOption, selectFreshConversationOption, ...users];
    } catch (error) {
      console.error("Error loading users:", error);
      return [];
    }
  };

  const extractUserInfo = (value) => {
    const [userId, gender] = value.value.split("-");
    return { userId, gender };
  };

  const handleUserChange = (selectedOption) => {
    const { gender } = extractUserInfo(selectedOption);
    setGender(gender === "male" ? "female" : "male");
    setSelectedUsers(selectedOption);
  };

  const loadUsersSender = async (inputValue) => {
    try {
      const response = await getUserList(
        0,
        20,
        inputValue,
        "all",
        "all",
        "all",
        "all",
        "createdAt",
        null,
        null
      );

      return response.data.data.users.map((user) => ({
        value: `${user._id}-${user.gender}`,
        label: (
          <div className="flex items-center">
            <img
              src={
                user?.images[0]?.url
                  ? imgBaseURl + user?.images[0]?.url
                  : dummmyImg
              }
              alt={user.name}
              className="w-8 h-8 rounded-full mr-2"
              width={50}
              height={40}
              style={{ marginRight: "10px" }}
            />
            {user.name} ({user.email ? user.email : user.phoneNumber})
          </div>
        ),
      }));
    } catch (error) {
      console.error("Error loading users:", error);
      return [];
    }
  };

  const CustomOption = (props) => {
    const { data, isSelected, innerRef, innerProps } = props;

    return (
      <div ref={innerRef} {...innerProps} className="custom-option">
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => null}
          style={{ marginRight: "8px", marginLeft: "8px" }}
        />
        <label>{data.label}</label>
      </div>
    );
  };

  const handleChange = (selectedOptions) => {
    if (!selectedOptions) {
      setSelectRecivers([]);
      return;
    }

    const isSelectAll = selectedOptions.some(
      (option) => option.value === "select-all"
    );

    const isFreshConversation = selectedOptions.some(
      (option) => option.value === "fresh-conversation"
    );

    if (isSelectAll) {
      setSelectRecivers([{ value: "select-all", label: "Select All" }]);
    } else if (isFreshConversation) {
      setSelectRecivers([
        { value: "fresh-conversation", label: "Fresh Conversation" },
      ]);
    } else {
      setSelectRecivers(selectedOptions);
    }
  };

  return (
    <div className="">
      <div className="">
        <div className="mb-4">
          <h3 className="mb-1 font-w600 text-black ">Send Message</h3>
        </div>

        <div className="d-block justify-content-between align-items-center">
          <div className="d-flex justify-content-between align-items-center">
            <div
              className="col-12"
              style={{ display: "flex", columnGap: "10px" }}
            >
              {" "}
              <div className="mb-2 mb-md-0 w-100">
                <label className="text-black font-w600 fs-14">
                  Filter by Gender
                </label>

                <select
                  className="form-control"
                  value={gender}
                  onChange={(e) => {
                    setGender(e.target.value);
                    dispatch(genderFilterPost(e.target.value));
                  }}
                >
                  <option value={"all"}>All</option>
                  <option value={"male"}>Male</option>
                  <option value={"female"}>Female</option>
                  <option value={"premium"}>Member</option>
                  <option value={"nonPremium"}>Non Member</option>
                  <option value={"pastMember"}>Past Member</option>
                </select>
              </div>
              <div className="mb-2 mb-md-0 w-100">
                <label className="text-black font-w600 fs-14">
                  Sign Up Complete
                </label>

                <select
                  className="form-control"
                  value={isFormFilled}
                  onChange={(e) => {
                    setIsFormFilled(e.target.value);
                    dispatch(formFilledFilterPost(e.target.value));
                  }}
                >
                  <option value={"all"}>All</option>
                  <option value={true}>Completed</option>
                  <option value={false}>Not Completed</option>
                </select>
              </div>
              <div className="mb-2 mb-md-0 w-100">
                <label className="text-black font-w600 fs-14">
                  Filter by Active Status
                </label>
                <select
                  className="form-control"
                  value={activeStatus}
                  onChange={(e) => {
                    setActiveStatus(e.target.value);
                    dispatch(activeStatusFilterPost(e.target.value));
                  }}
                >
                  <option value={"createdAt"}>Created At</option>
                  <option value={"lastActive"}>Last Active</option>
                </select>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center gap-3 mt-2">
            {" "}
            <div className="mb-2 mb-md-0 w-100">
              <label className="text-black font-w600 fs-14">
                Filter by Status
              </label>

              <select
                className="form-control"
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                  dispatch(statusFilterPost(e.target.value));
                }}
              >
                <option value={"all"}>All</option>
                <option value={"enable"}>Enabled</option>
                <option value={"disable"}>Disabled</option>
                <option value={"deactivate"}>Deactivated</option>
                <option value={"delete"}>Deleted</option>
                <option value={"adminDeleted"}>Deleted By Admin</option>
              </select>
            </div>
            <div className="mb-2 mb-md-0 w-100">
              <label className="text-black font-w600 fs-14">
                Filter by Sign Up
              </label>
              <select
                className="form-control"
                value={signUpType}
                onChange={(e) => {
                  setSignUpType(e.target.value);
                  dispatch(typeFilterPost(e.target.value));
                }}
              >
                <option value={"all"}>All</option>
                <option value={"email"}>Email</option>
                <option value={"phoneNumber"}>Phone Number</option>
                <option value={"social"}>Social Login</option>
              </select>
            </div>
            <button
              type="button"
              className="btn btn-primary py-2"
              onClick={() => {
                dispatch(resetFilterPost());
                setGender("all");
                setIsFormFilled("all");
                setStatus("all");
                setSignUpType("all");
                setActiveStatus("createdAt");
              }}
            >
              Reset
            </button>
          </div>
        </div>

        <form onSubmit={onSubmit} ref={formRef} style={{ marginTop: "50px" }}>
          <div className="form-group">
            <label className="mb-2">
              <strong className="">Sender</strong>
            </label>
            <Select
              cacheOptions
              defaultOptions
              value={selectedUsers}
              onChange={handleUserChange}
              loadOptions={loadUsersSender}
              placeholder="Search and select user..."
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>

          <div className="form-group">
            <label className="mb-2">
              <strong className="">Recivers</strong>
            </label>
            <Select
              key={`${currentPage}-${gender}-${status}-${isFormFilled}-${signUpType}-${activeStatus}-${endDate}`}
              isMulti
              cacheOptions
              defaultOptions
              value={selectedRecivers}
              onChange={handleChange}
              loadOptions={loadUsers}
              placeholder="Search and select users..."
              className="basic-multi-select"
              classNamePrefix="select"
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{ Option: CustomOption }}
            />
          </div>
          <div className="form-group">
            <label className="mb-2 ">
              <strong className="">Message</strong>
            </label>
            <textarea
              type="text"
              className="form-control"
              name="message"
              value={formData.message}
              onChange={handleInput}
              placeholder="Enter message"
              style={{ minHeight: "200px" }}
            />
            {errors.message && (
              <div className="text-danger fs-12">{errors.message}</div>
            )}
          </div>

          <div className="form-group">
            <div
              className="custom-control custom-checkbox d-flex align-items-center gap-2 p-2"
              style={{
                background: "#f8f9fa",
                borderRadius: "8px",
                border: "1px solid #e9ecef",
              }}
            >
              <input
                type="checkbox"
                className="custom-control-input"
                id="isPinned"
                name="isPinned"
                checked={hideFromSender}
                onChange={(e) => {
                  setHideFromSender(e.target.checked);
                }}
                style={{
                  width: "18px",
                  height: "18px",
                  cursor: "pointer",
                }}
              />
              <label
                className="custom-control-label d-flex align-items-center gap-2 mb-0"
                htmlFor="isPinned"
                style={{ cursor: "pointer" }}
              >
                Hide from sender
                {hideFromSender && (
                  <span
                    className="badge bg-primary"
                    style={{ fontSize: "0.75rem" }}
                  >
                    Hidden
                  </span>
                )}
              </label>
            </div>
          </div>

          <div className="text-center">
            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={loader}
            >
              {loader ? (
                <>
                  <ButtonLoader /> Send
                </>
              ) : (
                "Send"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SendMessages;
