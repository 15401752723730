import React from "react";
import { Modal } from "react-bootstrap";

export default function ConfirmationDeleteAccount({ show, onHide, onDelete }) {
  return (
    <Modal className="modal fade" show={show} centered>
      <div role="document">
        <div>
          <div className="modal-header">
            <h4 className="modal-title fs-20">Confirm Deletion</h4>
          </div>
          <div className="modal-body text-center">
            <p>Are you sure you want to delete this account? </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={onHide}
              className="btn btn-secondary"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={onDelete}
              className="btn btn-danger"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
